import GoogleTagManagerProvider from "./gtm";
import KlaviyoProvider from "./klaviyo";

export interface IAnalyticsProvider {
  trackEvent(event: string, data: Record<string, any>): Promise<void>;
}

let instance: TrackingService | null = null;

class TrackingService {
  private providers: IAnalyticsProvider[] = [];
  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    this.addProvider(new GoogleTagManagerProvider());
    this.addProvider(new KlaviyoProvider());
    instance = this;
  }

  addProvider(provider: IAnalyticsProvider): void {
    this.providers.push(provider);
  }

  async trackEvent(event: string, data: Record<string, any>): Promise<void> {
    const trackingPromises = this.providers.map((provider) => {
      try {
        return provider.trackEvent(event, data);
      } catch (e: unknown) {
        console.error(e);
        return Promise.reject();
      }
    });
    await Promise.all(trackingPromises);
  }
}

const trackingService = Object.freeze(new TrackingService());
export default trackingService;
