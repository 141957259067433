import {ClubLabel} from "../types/types";

export function mapClubLabel(str: string): ClubLabel {
  if (str === "club_uk") {
    return "clubUK";
  } else if (str === "club_au") {
    return "clubAU";
  } else if (str === "club_us") {
    return "clubUS";
  } else if (str === "club_nz") {
    return "clubNZ";
  } else if (str === "club_pt") {
    return "clubPT";
  } else if (str === "club_crfu") {
    return "clubCRFU";
  }

  throw new Error("Unsupported club" + str);
}
