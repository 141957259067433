import {Appearance} from "@stripe/stripe-js";
import {CurrencyCode} from "../types/storefront.generated";

interface IConfig {
  activeClub: string;
  countryCode: string;
  currencyCode: CurrencyCode;
  environment: string;
  appUrl: string;
  checkoutUrl: string;
  storeUrl: string;
  stripeKey: string;
  stripeSecret: string;
  windcaveUrl: string;
  windcaveSecret: string;
  windcaveWebhookUrl: string;
  laybuyUrl: string;
  laybuySecret: string;
  zipUrl: string;
  zipAuthUrl: string;
  zipClientId: string;
  zipClientSecret: string;
  gemUrl: string;
  gemMid: string;
  gemSecret: string;
  paypalUrl: string;
  paypalClientId: string;
  paypalClientSecret: string;
  stripeWebhookSecret: string;
  shopifyAccessToken: string;
  shopifyAppPassword: string;
  shopifyAdminUrl: string;
  shopifyStorefrontUrl: string;
  defaultShippingCity: string;
  defaultShippingProvince: string;
  supabaseUrl: string;
  supabaseKey: string;
  sentryDsn: string;
  stripeTheme: Appearance;
  fonts: {
    family: string;
    src: string;
    weight: string;
  }[];
}

const config: IConfig = {
  activeClub: process.env.REACT_APP_ACTIVE_CLUB ?? "club_nz",
  countryCode: process.env.REACT_APP_CHECKOUT_COUNTRY ?? "NZ",
  currencyCode: process.env.REACT_APP_CHECKOUT_CURRENCY as CurrencyCode ?? "NZD",
  environment: process.env.REACT_APP_CHECKOUT_ENV ?? "production",
  appUrl: process.env.REACT_APP_URL ?? "",
  checkoutUrl: process.env.REACT_APP_CHECKOUT_URL ?? "",
  storeUrl: process.env.REACT_APP_STORE_URL ?? "",
  stripeKey: process.env.REACT_APP_STRIPE_KEY ?? "",
  stripeSecret: process.env.STRIPE_SECRET_KEY ?? "",
  stripeWebhookSecret: process.env.STRIPE_WEBHOOK_SECRET ?? "",
  windcaveUrl: process.env.WINDCAVE_URL ?? "",
  windcaveSecret: process.env.WINDCAVE_SECRET ?? "",
  windcaveWebhookUrl: process.env.WINDCAVE_WEBHOOK_URL ?? "",
  laybuyUrl: process.env.LAYBUY_URL ?? "",
  laybuySecret: process.env.LAYBUY_SECRET ?? "",
  zipUrl: process.env.ZIP_URL ?? "",
  zipAuthUrl: process.env.ZIP_AUTH_URL ?? "",
  zipClientId: process.env.ZIP_CLIENT_ID ?? "",
  zipClientSecret: process.env.ZIP_CLIENT_SECRET ?? "",
  gemUrl: process.env.GEM_URL ?? "",
  gemMid: process.env.GEM_MID ?? "",
  gemSecret: process.env.GEM_SECRET ?? "",
  paypalUrl: process.env.PAYPAL_URL ?? "",
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "",
  paypalClientSecret: process.env.PAYPAL_CLIENT_SECRET ?? "",
  shopifyAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN ?? "",
  shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD ?? "",
  shopifyAdminUrl: process.env.SHOPIFY_ADMIN_URL ?? "",
  shopifyStorefrontUrl: process.env.REACT_APP_SHOPIFY_STOREFRONT_URL ?? "",
  defaultShippingCity: process.env.REACT_APP_DEFAULT_SHIPPING_CITY ?? "Auckland",
  defaultShippingProvince: process.env.REACT_APP_DEFAULT_SHIPPING_PROVINCE ?? "AUK",
  supabaseUrl: process.env.SUPABASE_URL ?? "",
  supabaseKey: process.env.SUPABASE_KEY ?? "",
  sentryDsn: process.env.REACT_APP_SENTRY_DNS ?? "",
  stripeTheme: {
    theme: 'stripe',
    variables: {
      fontFamily: '"Axiforma", Arial, sans-serif',
      fontLineHeight: '1.5',
      colorPrimary: '#30313d',
      colorPrimaryText: '#fff',
      colorBackgroundText: '#fff',
      borderRadius: '1.5rem',
    },
    rules: {
      '.LinkButton' : {
        borderRadius: '9999px',
      }
    }
  },
  fonts: [
    {
      family: 'Axiforma',
      src: 'url(/fonts/axiforma/axiforma-bold.woff2)',
      weight: '700',
    },
    {
      family: 'Axiforma',
      src: 'url(/fonts/axiforma/axiforma-extrabold.woff2)',
      weight: '800',
    },
    {
      family: 'Axiforma',
      src: 'url(/fonts/axiforma/axiforma-light.woff2)',
      weight: '300',
    }
  ]
};
export default config;
