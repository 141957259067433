import React from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "../utils/classNames";
import formatPrice from "../utils/formatPrice";
import {IShippingMethod} from "../types/types";
import config from "../utils/config";

interface IShippingRateProps {
  rates: IShippingMethod[];
  selected: IShippingMethod | null;
  disabled: boolean;
  onShippingRateSelected: (shippingRateHandle: IShippingMethod) => void;
}

const ShippingRates = ({
  rates,
  selected,
  disabled,
  onShippingRateSelected,
}: IShippingRateProps) => {
  if (!selected && rates.length > 0) {
    onShippingRateSelected(rates[0]);
  }

  return selected && (
      <RadioGroup
        value={selected}
        by="handle"
        disabled={disabled}
        onChange={(newShippingRate) => onShippingRateSelected(newShippingRate)}
        className={disabled ? 'opacity-50' : ''}
      >
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="-space-y-px rounded-2xl bg-white">
          {rates.map((rate, rateIdx) => (
            <RadioGroup.Option
              key={rate.handle}
              value={rate}
              className={({ checked }) =>
                classNames(
                  rateIdx === 0 ? "rounded-tl-3xl rounded-tr-3xl" : "",
                  rateIdx === rates.length - 1
                    ? "rounded-bl-3xl rounded-br-3xl"
                    : "",
                  checked ? "z-10 border-gray-200 bg-gray-50" : "border-gray-200",
                  "relative flex cursor-pointer border p-4 focus:outline-none",
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? "bg-white border-gray-600"
                        : "bg-white border-gray-300",
                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border border-2 flex items-center justify-center",
                    )}
                    aria-hidden="true"
                  >
                    <span className={`rounded-full ${checked ? 'bg-gray-600' : 'bg-white'} w-2 h-2`} />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-gray-900" : "text-gray-900",
                        "block text-sm font-medium",
                      )}
                    >
                      {rate.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-gray-700" : "text-gray-500",
                        "block text-sm font-light",
                      )}
                    >
                      {formatPrice(rate.price, config.currencyCode)}
                    </RadioGroup.Description>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
  );
};
export default ShippingRates;
